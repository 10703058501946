<template>
  <div class="w-full h-screen flex justify-center items-center">
    <div class="absolute inset-0 z-0">
        <img src="../../assets/images/bg.jpg" alt=""
            class="w-full h-full object-cover filter blur-lg brightness-100">
    </div>
    <div class="w-full m-2 relative  md:w-1/4 md:m-0 shadow-2xl p-4  rounded-xl ">
      <div class="flex justify-center">
        <img src="../../assets/icons/ic-logo.svg" class="h-32 w-40">
      </div>
      <select v-model="$i18n.locale">
        <option value="fr">Francais</option>
        <option value="en">English</option>
    </select>
      <div class="form mt-8">
        <div class="form-item mt-4">
          <h3 class="text-xl text-black mb-2">
            {{$t('email')}}
          </h3>
          <input class="text-xl border rounded-xl h-16 w-full p-2 text-black" v-model="email">
        </div>
        <div class="form-item mt-4">
          <h3 class="text-xl text-black mb-2">
            {{$t('password')}}
          </h3>
          <input type="password" class="text-xl border rounded-xl h-16 w-full p-2 text-black" v-model="password">
        </div>
        <div @click="goSignIn" class="mt-4 h-16 w-full flex items-center cursor-pointer rounded-xl next-button justify-center text-center text-xl text-white">
          <span v-if="!showLoader" >{{ $t('next') }}</span>
          <pulse-loader v-if="showLoader"  :color="color"></pulse-loader>
        </div>
        <div class="create-account flex justify-center mt-3 text-black cursor-pointer mb-3" @click="goNext">
          {{ $t('createAccount')  }}
        </div>
        <div  class="create-account flex justify-center mt-3 text-black cursor-pointer mb-3" @click="resetPassword">
          {{ $t('Réitiliser mot de passe')  }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import firebase from 'firebase'

export default {
  name: 'SignUp',
  components: { PulseLoader },
  data () {
    return {
      color: '#ffffff',
      showLoader: false,
      email: '',
      password: ''
    }
  },
  methods: {
    goSignIn () {
      if (this.email === '' || this.password === '') {
        alert('All inputs are required')
      } else {
        this.showLoader = true
        const vm = this
        firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then((result) => {
            vm.$router.push('/')
          })
          .catch((err) => {
            alert(err)
          })
      }
    },
    goNext () {
      // this.$router.push('/sign')
    window.location.href = "https://play.google.com/store/apps/details?id=com.nolido.mt&hl=fr_CH&gl=US&pli=1";

    },
    resetPassword() {
      const email = prompt("Veuillez saisir votre adresse e-mail :")
      if (email) {
        firebase.auth().sendPasswordResetEmail(email)
          .then(() => {
            alert("Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.")
          })
          .catch((error) => {
            console.error(error)
            alert("Une erreur s'est produite lors de l'envoi de l'e-mail de réinitialisation de mot de passe.")
          })
      }
    }
  
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.next-button {
  background-color: $color-primary;
}
</style>
{ 
  "lastname": "Lio",
  "firstname": "Bis",
  "email": "lio@bis.com",
  "phone": "+22966666666",
  "country": "BJ"
}